import { useState } from "react";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router,
    Route,
    Switch,
    Navigate,
    Routes}
    from 'react-router-dom';

    const Beta_Signup = () => {

        const [email_1, setEmail_1] = useState("");
        const [email_2, setEmail_2] = useState("");
        const [email_3, setEmail_3] = useState("");

        const handleSubmit = (event) => {

            event.preventDefault();
            alert(`The email you entered was: ${email_1}`)
            alert(`The email you entered was: ${email_2}`)
            alert(`The email you entered was: ${email_3}`)
        }   

        return (
            <div className="betasignup">
                    <Helmet>
                        <title>Ziporama's Two Beta Tests</title>
                        <meta name="description" content="Ziporama is beta-testing. There are two beta tests. The first compresses any file less than 16 bits to 0.05%.
                        The second is for smartphones. You get 200 times more data for your smartphone plan. Both beta-tests are free."></meta>
                    </Helmet>
                    <Navbar/>
                    <p class="one">There are two beta-tests.  You can sign up for both.</p>
                    
                    <p class="two">1. Compress a file to 0.05%. The first beta-test is for 8-bit files.
                        An example of an 8-bit file is JPEG/JPG. The next beta-test is
                        for 16-bit files. An example of a 16-bit is a RAW file.</p>

                    <p class="two">To beta-test, you'll need a file or files (as many you'd like)
                        and agree to at least a ten minute interview. Beta-testing
                        is free.
                    </p>

                    <form onSubmit={handleSubmit}>
                        <label for="email_Beta_1">Email Address:
                        <input 
                            type="text" 
                            value={email_1}
                            onChange={(e) => setEmail_1(e.target.value)}
                        />
                         </label>
                    </form>

                    <p class="two">2. This is only for residents of the United States of America. Whatever
                        your cell phone plan is, Ziporama's software will give you 200 times more
                        data.</p>
    
                    <p class="two">How it works: when you use the Internet on your cellphone -- and you're
                        not using Wifi -- all of your data will be routed through Ziporama's
                        servers. At this point, it's going to be compressed and then sent to
                        your phone, where it's going to be decompressed.</p>
    
                    <p class="two">To beta-test, you'll need to be the owner of a smartphone and
                        agree to use the software for at least one month. There 
                        is no charge for either the software or beta-testing.
                    </p>
    
                    <p class="two">Sign up here to do this beta-test.</p>

                    <form>
                    <label for="email_Beta_2">Email Address:</label>
                    <input
                        type="text"
                        id={email_2}
                        onChange={(e) => setEmail_1(e.target.value)}/>
                    </form>
                </div>
        );
        
    }

export default Beta_Signup;