import React, { useState } from "react";
import { ReactDOM } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router,
    Route,
    Switch,
    Navigate,
    Routes}
    from 'react-router-dom';

const Decompress = () => {
    return (
        <div className="about">
                <Helmet>
                        <title>Ziporama: Decompress a File or a Set of Files</title>
                        <meta name="description" content="To decompress a file, first sign up to beta-test."></meta>
                </Helmet>
                <Navbar/>
                <p class="three">To decompress a file or a set of files, sign up to <Link to="/Beta_Signup">Beta Test</Link>
                </p>
        </div>
    );
    
}

export default Decompress;