import Home_Page from './Home_Page_Version_1';
import Compress from './Compress_Version_1';
import Decompress from './Decompress_Version_1';
import Beta_Signup from './Beta_Signup_Version_2';
import Pricing from './Pricing_Version_1';
import About from './About_Version_1';
import Contact_Form from './Contact_Form_Version_1';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router,
  Route,
  Switch,
  Navigate,
  Routes}
  from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
          <Route
            exact path="/"
            element={<Home_Page/>}
          />
          <Route
          exact path="/Compress"
          element={<Compress/>}
          />
          <Route
          exact path="/Decompress"
          element={<Decompress/>}
          />
          <Route
          exact path="/Beta_Signup"
          element={<Beta_Signup/>}
          />
          <Route
          exact path="/Pricing"
          element={<Pricing/>}
          />
          <Route
          exact path="/About"
          element={<About/>}
          />
          <Route
          exact path="/Contact_Form"
          element={<Contact_Form/>}
          />
      </Routes>
    
  </Router>
  );
}

export default App;
