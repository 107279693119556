import React from "react";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router,
    Route,
    Switch,
    Navigate,
    Routes}
    from 'react-router-dom';

const About = () => {
    return (
        <div className="about">
            <Helmet>
                <title>About Ziporama</title>
                <meta name="description" content="The Ziporama compression algorithm was developed by Sam Sachdev. A patent, for the compression algorithm, has been applied for."></meta>
            </Helmet>
                <Navbar/>
                <p class="three">The Ziporama compression algorithm was developed by
                Sam Kumar Sachdev. It took him nine years to develop it.
                The company is located in North Bethesda, Maryland (USA).
                </p>
                <p class="three">A patent, for the compression algorithm, has been applied for.</p>
                <p class="three">In early 2024, Ziporama is going to move to San Francisco, California.</p>
        </div>
    );
    
}

export default About;