import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <nav className = "navbar">
            <ul>
                <li><h2><Link to="/">ZIPORAMA</Link></h2></li>
                <li><Link to="/Beta_Signup">SIGN UP TO BETA TEST</Link></li>
                <li><Link to="/Compress">COMPRESS</Link></li>
                <li><Link to="/Decompress">DECOMPRESS</Link></li>
                <li><Link to="/Pricing">PRICING</Link></li>
                <li><Link to="/About">ABOUT</Link></li>
                <li><Link to="/Contact_Form">CONTACT</Link></li>
            </ul>
        </nav>
    );
    
}

export default Navbar;