import React, { useState } from "react";
import { ReactDOM } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router,
    Route,
    Switch,
    Navigate,
    Routes}
    from 'react-router-dom';

const Compress = () => {

  return (
    <div className="about">
                <Helmet>
                        <title>Ziporama: Compress a File or a Set of Files</title>
                        <meta name="description" content="To compress a file, sign up to beta-test. You'll then receive a key to compress."></meta>
                </Helmet>
                <Navbar/>
                <p class="three">To compress a file or a set of files, sign up to <Link to="/Beta_Signup">Beta Test</Link>
                </p>
      </div>
  );
}

export default Compress;