import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home_Page = () => {
    return (
        <div>
            <title><meta name="Compress any file less than 16 bits to 0.05%. Get 200 times more data for your smartphone data plan.
            Ziporama is beta testing."></meta></title>

            <Navbar/>
            <div class = "grid-container">
            <div class="grid-item"><h3><p class="one">Ziporama is a revolutionary new compression algorithm.</p></h3>
            <h3><p class="one">Compress any file less than 16 bits to 0.05%. Lossless.
            This means make a file 200 times smaller, without losing any data.
            This includes JPEGs, Raw files, and the HDRi raw data format. (See all <a>files</a>)
            </p></h3>
            <h3><p class="one">Get 200 times more data for your smartphone.</p></h3>
             <h3><p class="two">Start <Link to="/Beta_Signup">beta-testing</Link></p></h3></div>
            <div class="grid-item-2"><img src={require("./ZOR Logo Small.png")}></img></div>
            </div>
        </div>
    );
    
}

export default Home_Page;