import React, { useState } from "react";
import { ReactDOM } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router,
    Route,
    Switch,
    Navigate,
    Routes}
    from 'react-router-dom';

const Pricing = () => {
    return (
        <div className="about">
                <Helmet>
                        <title>Ziporama: Pricing of the Compress/Decompress Software</title>
                        <meta name="description" content="Ziporama is developing its pricing algorithm. If you want to help with this, contact us
                        and let us know that you'd like to help develop the pricing points"></meta>
                </Helmet>
                <Navbar/>
                <p class="three">This page is under construction.
                </p>
        </div>
    );
    
}

export default Pricing;