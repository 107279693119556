import React, { useState } from "react";
import { ReactDOM } from "react";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router,
    Route,
    Switch,
    Navigate,
    Routes}
    from 'react-router-dom';

const Contact_Form = () => {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [textarea, setTextarea] = useState("The content of the textarea");

    const handleSubmit = (event) => {
      event.preventDefault();
    }

    const handleChange = (event) => {
      setTextarea(event.target.value)
    }

  return (
    <div className="contactform">
        <Helmet>
            <title>Ziporama: Contact Us</title>
            <meta name="description" content="All messages to Ziporama will be replied to within seven days."></meta>
        </Helmet>
        <Navbar/>
        <p class="one">CONTACT FORM</p>
        <p class="two">* All responses will receive an automatic response, which will
        include your message.
        </p>

        <p class="two">* All (legitmate) responses will receive a response in
        seven days.
        </p>
        <form onSubmit={handleSubmit}>
                
                <label_1>FIRST NAME:

                    <p></p>
                    
                    <input
                    type="text"
                    name="firstname"
                    value = {firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    />

                <p></p>
                </label_1>

                <p></p>
                
                <label_2>LAST NAME:

                    <p></p>
                    
                    <input
                    type="text"
                    name="firstname"
                    value = {firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    />
                </label_2>

                <p></p>

                <label_2>EMAIL:

                    <p></p>
                    
                    <input
                    type="text"
                    name="email"
                    value = {email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                
                </label_2>

                <p></p>

                <label_2>MESSAGE:

                  <p></p>

                  <textarea value={textarea} onChange={handleChange}></textarea>
               
                </label_2>

                <p></p>

                <input type="submit"/>

            </form>
    </div>
  );
}

export default Contact_Form;